// @ts-strict-ignore

// Import Thirdparty styles
import 'animate.css/animate.min.css'
import 'aos/dist/aos.css'
import 'react-quill/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css' // react-tooltip v5
import 'select2/dist/css/select2.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

// Import custom style files
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import '../public/assets/css/new-theme/feather/feather.css'
import '../public/assets/css/new-theme/newCustom.scss'
import '../public/assets/css/new-theme/newDaypicker.scss'
import '../public/assets/css/new-theme/newSchedule.scss'
import '../public/assets/css/new-theme/react-slick.scss'
import '../public/assets/css/new-theme/reactQuill.scss'
import '../public/assets/css/new-theme/reviewGraph.scss'
import '../public/assets/css/new-theme/schedule-dropdown.scss'
import '../public/assets/css/new-theme/team.scss'

// Import Tailwind
import '../public/assets/css/new-theme/tailwind.css'

import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { dehydrate, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AOS from 'aos'
import { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { NextPageContext } from 'next/types'
import { lazy, Suspense, useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PopupProvider from '../components/basic/popup/PopupProvider'
import { initGTMScript } from '../helpers/gtmHelper'
import { getIdFromJwtToken } from '../modules/auth'
import { axiosGet } from '../modules/axiosHelper'
import { LocaleProvider } from '../modules/context/LocaleContext'
import { UserProvider } from '../modules/context/UserContext'
import { getEnv } from '../modules/envUtil'
import { IS_PRODUCTION } from '../modules/envVars'
import useSessionStorage from '../modules/hooks/common/useSessionStorage'
import { LOCALE_KO } from '../modules/i18n/config'
import { userQueryApi } from '../modules/react-query/queryApis'
import { setAdHistory } from '../modules/utils'
import { COOKIE_LIST, GOOGLE_CLIENT_ID, NEXT_PUBLIC_SENTRY_DSN_TEENS } from '../modules/vars'
import { wrapper } from '../reducer/store'

declare global {
  interface Window {
    dataLayer: any
    Kakao: any
    gapi: any
    WEBSPELLCHECKER_CONFIG: any
    IMP: any
    TossPayments: any
    clipboardData: any
    copyToClipboardFF: any
    fbAsyncInit: any
    FB: any
  }
}

const ignorableErrors = [
  'The voice you passed is not valid or the voices have not been loaded yet.',
  '_next/static/chunks',
  // NextJS - next-head-count error
  'TypeError: Cannot read properties of null (reading "content")',
  `TypeError: Cannot read properties of null (reading 'content')`,
  `Cannot read properties of null (reading 'content')`,
  '_next/static/chunks',
  'Non-Error promise rejection captured',
]

const extractCookie = (ctx: NextPageContext, cookieName: string): string | undefined => {
  // If req does not exists -> early return undefined
  if (!ctx.req) return undefined

  const sessionRoleKeyVal: string[] =
    ctx.req.headers.cookie
      ?.split('; ') // returns -> "[cookie-name]=[cookie-value]"[]
      .map((cookieStr) => cookieStr.split('=')) // returns -> ["[cookie-name]", "[cookie-value]"]
      .filter((cookieArr) => cookieArr[0] === cookieName)[0] ?? // returns -> cookie value
    [] // return empty array if undefined

  const value = sessionRoleKeyVal.length > 0 ? sessionRoleKeyVal[1] : undefined
  return value
}

/**
 * React Query Devtools in production
 * NODE_ENV === 'production' 이어도 볼 수 있도록 처리
 * https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
 */
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
)

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  /**
   * App.tsx State
   */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  // 직전 페이지 route 저장
  const [, setPrevPage] = useSessionStorage('prevPage', null)

  useEffect(() => {
    return () => {
      setPrevPage(router.pathname)
    }
  }, [router])

  /**
   * React Query Devtools in production
   * NODE_ENV === 'production' 이어도 볼 수 있도록 처리
   * https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
   */
  const [showDevtools, setShowDevtools] = useState<boolean>(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  /**
   * Setting Ad History
   */

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAdHistory(router.query)
      initGTMScript()
    }
  }, [])

  /**
   * AOS animation library
   */
  const initAOS = () => {
    AOS.init({
      duration: 500,
      easing: 'ease-out-quad',
      once: true,
      startEvent: 'load',
    })
  }

  useEffect(() => {
    if (process.browser) {
      initAOS()
    }

    window.dispatchEvent(new Event('resize'))
  }, [])

  useEffect(() => {
    // Sentry
    const domain = 'www.ringleteens.com'

    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',

      dsn: NEXT_PUBLIC_SENTRY_DSN_TEENS,
      environment: window?.location?.host === domain ? 'production' : 'qa',
      ignoreErrors: [
        // 'TypeError: Cannot read properties of null (reading "content")',
        // `TypeError: Cannot read properties of null (reading 'content')`,
        // /Cannot read properties of null \(reading 'content'\)/,
        // '_next/static/chunks',
      ],

      // This enables automatic instrumentation (highly recommended), but is not
      // necessary for purely manual usage
      integrations: [
        new Integrations.BrowserTracing({
          tracePropagationTargets: [],
        }),
      ],
      // To set a uniform sample rate
      tracesSampleRate: 0,
      denyUrls: ['local.ringleplus.com'],
      beforeSend(event, hint) {
        const error = hint.originalException
        if (typeof error == 'string') {
          ignorableErrors.map((item, i) => {
            if (error.includes(item)) {
              return null
            }
          })
        } else if (error instanceof Error) {
          ignorableErrors.map((item, i) => {
            if (error.message.includes(item)) {
              return null
            }
          })
        }
        return event
      },
    })
  }, [])

  return (
    <>
      <Head>
        <link rel="icon" href={`/assets/favicon_teens.ico`} type="image/x-icon" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <UserProvider>
            <LocaleProvider>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleReCaptchaProvider
                  reCaptchaKey={getEnv('NEXT_PUBLIC_RECAPTHA_TEENS_SITE_KEY')}
                  scriptProps={{
                    async: true, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: 'body', // optional, default to "head", can be "head" or "body",
                    nonce: undefined,
                  }}
                >
                  <PopupProvider />
                  <Component {...pageProps} />
                  <div id="ds-modal"></div>
                  <div id="ds-bottom-sheet"></div>
                </GoogleReCaptchaProvider>
              </GoogleOAuthProvider>
            </LocaleProvider>
          </UserProvider>
          {!IS_PRODUCTION && showDevtools && (
            <Suspense fallback={null}>
              <ReactQueryDevtoolsProduction initialIsOpen panelProps={{ className: 'min-h-[300px]' }} />
            </Suspense>
          )}
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {}

  const queryClient = new QueryClient()
  const sessionRole = extractCookie(ctx, COOKIE_LIST.SESSION_ROLE)
  const jwtToken = extractCookie(ctx, COOKIE_LIST.JWT_TOKEN)
  const userId = getIdFromJwtToken(jwtToken)

  // If Session Role & JWT Token Exists
  if (sessionRole && jwtToken && userId) {
    // Prefetch User Query Based on User + Session Role
    // FIXME: ringleId 로 판단하는 로직 추후 제거
    await queryClient.prefetchQuery(userQueryApi.getQueryKey(userId), async () => {
      const res = await axiosGet(userQueryApi.api(), { initialToken: jwtToken })
      return res
    })
  }

  // Check if component has getInitialProps -> fetch props
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return {
    pageProps: {
      ...pageProps,
      dehydratedState: dehydrate(queryClient),
    },
  }
}

export const redirectRoot = (ctx, headerObj = {}) => {
  const { lang = LOCALE_KO } = ctx.query
  if (ctx.res) {
    ctx.res.writeHead(302, {
      Location: `/${lang}/`,
      ...headerObj,
    })
    ctx.res.end()
  }
}

export default wrapper.withRedux(MyApp)
