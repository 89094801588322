// @ts-strict-ignore
import { formatISO } from 'date-fns'
import fetch from 'isomorphic-unfetch'
import qs from 'qs'
import apis from '../../modules/apis'
import { getJwtToken } from '../../modules/auth'
import { axiosGet, axiosPost } from '../axiosHelper'

export const fetchMyTutors = async ({ page = 1, couponId }) => {
  const url = apis.tutor.myTutors(page, couponId)
  const response = await axiosGet(url)
  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }
  return {
    count: response?.count,
    tutors: tutors,
  }
}

export const fetchAvailableTutors = async ({ page = 1, couponId }) => {
  const url = apis.tutor.list(page, couponId)
  const response = await axiosGet(url)
  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }
  return {
    count: response?.count,
    tutors: tutors,
  }
}

export const fetchRisingStarTutors = async ({ page = 1, couponId }) => {
  const url = apis.tutor.newTutors(page, couponId)
  const response = await axiosGet(url)
  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }
  return {
    count: response?.count,
    tutors: tutors,
  }
}

export const fetchAllTutors = async ({ page = 1, couponId }) => {
  const url = apis.tutor.allTutors(page, couponId)
  const response = await axiosGet(url)
  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }
  return {
    count: response?.count,
    tutors: tutors,
  }
}

export const fetchNewUnoccupiedTutors = async ({ page = 1 }) => {
  const url = apis.tutor.newUnoccupiedTutors(page)
  const response = await axiosGet(url)
  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }
  return {
    count: response?.count,
    tutors: tutors,
  }
}

export const fetchOldUnoccupiedTutors = async ({ page = 1 }) => {
  const url = apis.tutor.oldUnoccupiedTutors(page)
  const response = await axiosGet(url)
  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }
  return {
    count: response?.count,
    tutors: tutors,
  }
}

export const fetchSearchedTutors = async ({ payload }) => {
  const url = apis.tutor.search(payload)
  const response = await axiosGet(url)
  if (response?.result) {
    return { count: response.count, tutors: response.result }
  }
}

export const fetchTutorProfile = async ({ tutorId, locale, couponId = null }) => {
  const url = apis.tutor.profile(tutorId, locale, couponId)
  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getJwtToken(),
      },
    })
    return res.json()
  } catch (e) {
    console.error(e)
  }
}

export const fetchTutorOpenedTimeSchedule = async ({
  from,
  to,
  tutorId,
  coupon_type_id, // coupon_bundle_id로 대체되어 deprecate 예정
  lesson_id,
  coupon_bundle_id,
}) => {
  const query = qs.stringify({
    date_from: formatISO(new Date(from)),
    date_to: formatISO(new Date(to)),
    tutor_id: tutorId,
    coupon_type_id,
    lesson_id,
    coupon_bundle_id,
  })
  const url = `${apis.matching.schedule()}?${query}`
  const response = await axiosGet(url)
  let dataArray = []
  if (response.schedules.length > 0) {
    dataArray = response.schedules.map((item, i) => {
      return {
        ...item,
        userapply_id: item.id,
      }
    })
  }
  return {
    dataArray: dataArray,
  }
}

export const fetchTutorsByTime = async ({ bundleId, creditId, year, month, day, hours, minutes }) => {
  const query = qs.stringify({
    year,
    month,
    day,
    hours,
    minutes,
    coupon_bundle_id: bundleId,
    coupon_type_id: creditId,
  })

  const url = `${apis.matching.scheduleAtTime()}?${query}`
  const response = await axiosGet(url)

  let tutors = []
  if (response) {
    tutors = response.tutors.map((tutor, i) => {
      return {
        ...tutor,
        name: tutor.first_name,
      }
    })
  }

  const data = {
    ...response,
    classtime_id: response.classtime_id,
    formatted_start_time: response.formatted_start_time,
    tutors: tutors,
  }
  return data
}

export const fetchTutorSaveAndDelete = async ({ tutorId, isAdd, type = null }) => {
  //  Time First
  const T_FAVORITE = 't-favorite'
  const T_RECOMMEND = 't-recommend'
  const T_ALL = 't-all'

  //  Tutor First
  const P_FAVORITE = 'p-favorite'
  const P_RECOMMEND = 'p-recommend'
  const P_AVAILABLE = 'p-available'

  let referrer = ''
  switch (type) {
    case T_FAVORITE:
      referrer = 'lesson/schedule/time/favorite'
      break
    case T_RECOMMEND:
      referrer = 'lesson/schedule/time/featured'
      break
    case T_ALL:
      referrer = 'lesson/schedule/time/available'
      break
    case P_FAVORITE:
      referrer = 'lesson/schedule/tutor/favorite'
      break
    case P_RECOMMEND:
      referrer = 'lesson/schedule/tutor/featured'
      break
    case P_AVAILABLE:
      referrer = 'lesson/schedule/tutor/available'
      break
  }

  const urls = isAdd ? apis.tutor.add() : apis.tutor.delete()
  const res = await axiosPost(urls, {
    tutor_id: tutorId,
    referrer,
  })
  return res
}

export const fetchSubmitLessons = async ({ auto, general }) => {
  let res_auto = { success: true, result: [], first_lesson: false, message: '' }
  let res_general = { success: true, result: [], first_lesson: false, message: '' }
  if (auto.length > 0) {
    res_auto = await axiosPost(apis.lessons.applyAuto(), {
      apply_obj: auto,
    })
    if (!res_auto) {
      res_auto = {
        success: false,
        result: [],
        first_lesson: false,
        message: 'Server Error. [E-001]',
      }
    }
  }
  if (general.length > 0) {
    res_general = await axiosPost(apis.lessons.apply(), {
      apply_obj: general,
    })
    if (!res_general) {
      res_general = {
        success: false,
        result: [],
        first_lesson: false,
        message: 'Server Error. [E-002]',
      }
    }
  }
  const res = {
    result: [...res_auto.result, ...res_general.result],
    message: res_auto.message + res_general.message,
    first_lesson: res_auto.first_lesson || res_general.first_lesson,
  }
  return res
}

export const fetchSubmitEditMaterial = async (payload) => {
  const response = await axiosPost(apis.lessons.editCourse(), payload)
  if (!response) {
    return { success: false, message: 'Server Error. [E-003]' }
  }
  return response
}

export const fetchSubmitEditTimeSelectMatching = async (payload) => {
  const response = await axiosPost(apis.lessons.editTimeForSelectMatching(), payload)
  if (!response) {
    return { success: false, message: 'Server Error. [E-007]' }
  }
  return response
}

export const fetchValidateCoupon = async (payload) => {
  const response = await axiosPost(apis.matching.validateCoupon(), payload)
  if (!response) {
    return { success: false, message: 'Server Error. [E-004]' }
  }
  return response
}

export const fetchValidateCouponForEdit = async (payload) => {
  const response = await axiosPost(apis.matching.validateCouponForEdit(), payload)
  if (!response) {
    return { success: false, message: 'Server Error. [E-005]' }
  }
  return response
}

export const fetchUnbook = async (payload) => {
  const response = await axiosPost(apis.matching.unbook(), payload)
  if (!response) {
    return { success: false, message: 'Server Error. [E-006]' }
  }
  return response
}

export const fetchMaterialSearch = async (params) => {
  const url = apis.course.search() + '?' + qs.stringify(params)
  const response = await axiosGet(url)
  return response
}
